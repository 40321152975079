import React from 'react';
import { hot } from 'react-hot-loader/root';
import { rem } from 'polished';

import { useBreakpoints } from '../../hooks/useBreakpoints';
import { Colors } from '../../styles/colors';
import { FontWeight } from '../../styles/typography';
import LinkViewModel from '../../view-models/LinkViewModel';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';
import AlertBanner from '../../components/AlertBanner/AlertBanner';

export type HeaderProps = {
  navigationLinks?: LinkViewModel[];
  serviceMenuLinks?: LinkViewModel[];
  /** Link and aria-label used for the logo (Go back to frontpage) */
  logo?: LinkViewModel;
  alertMessage?: string;
};

export const navigationLinkStyle = {
  marginLeft: [0, '20px', '24px', '30px'],
  fontSize: [rem(17), 2],
  fontWeight: FontWeight.Medium,
  lineHeight: [1.53, 1.71],
  color: [Colors.Black, Colors.Secondary],
  textDecoration: 'none',
  borderBottomColor: Colors.VeryLightPink,
  padding: ['16px 20px', 0],
  transition: 'color .3s ease',
  '&:hover': {
    color: Colors.Black,
  },
};

function Header({
  alertMessage,
  logo,
  navigationLinks,
  serviceMenuLinks,
}: HeaderProps) {
  const [sm] = useBreakpoints();

  // Initially we render both mobile and desktop navigation. Once we know the current breakpoint, we can remove the unused view.
  // This is why the `!==` toggle is used - By default it would be `undefined`, until the actual breakpoint state is known.
  return (
    <>
      {sm !== false && (
        <DesktopNavigation
          logo={logo}
          navigationLinks={navigationLinks}
          serviceMenuLinks={serviceMenuLinks}
        />
      )}
      {sm !== true && (
        <MobileNavigation
          logo={logo}
          navigationLinks={navigationLinks}
          serviceMenuLinks={serviceMenuLinks}
        />
      )}
      <AlertBanner message={alertMessage} />
    </>
  );
}

export default !process.env.HOT ? Header : hot(Header);
