import React from 'react';
import { Container } from '@charlietango/ui';
import { rem } from 'polished';

import Anchor from '../../components/Anchor/Anchor';
import { Colors } from '../../styles/colors';
import {
  MotionValue,
  motion,
  useTransform,
  useViewportScroll,
} from '../../utils/motion';
import LinkViewModel from '../../view-models/LinkViewModel';
import Navigation from './Navigation';
import ServiceMenu from './ServiceMenu';
import Logo from '../../components/Logo/Logo';

type Props = {
  logo?: LinkViewModel;
  navigationLinks?: LinkViewModel[];
  serviceMenuLinks?: LinkViewModel[];
};

function DesktopNavigation({ serviceMenuLinks, navigationLinks, logo }: Props) {
  const { scrollY } = process.env.SERVER
    ? { scrollY: new MotionValue<number>(0) }
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useViewportScroll();

  const distancePxValue = 37;
  const y = useTransform(
    scrollY,
    (value) => (Math.min(value / 2, distancePxValue) * -1) / 16 + 'rem',
  );
  const opacity = useTransform(
    scrollY,
    (value) => (value - distancePxValue) / (0 - distancePxValue),
  );

  return (
    <motion.header
      style={{ y }}
      initial={{ y: 0 }}
      sx={{
        display: ['none', 'block'],
        background: Colors.White,
        boxShadow: `0 1px 0 0 #eeeeee`,
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          pt: rem(14),
          pb: rem(24),
        }}
      >
        <Anchor prefetch {...logo} css={{ textDecoration: 'none' }}>
          <Logo sx={{ width: 46 }} />
        </Anchor>
        <div>
          <motion.div
            initial={{ opacity: 1 }}
            style={{ opacity }}
            css={{ textAlign: 'right' }}
          >
            <ServiceMenu links={serviceMenuLinks} />
          </motion.div>
          <div
            sx={{
              mt: '2rem',
              textAlign: 'right',
            }}
          >
            {navigationLinks && <Navigation links={navigationLinks} />}
          </div>
        </div>
      </Container>
    </motion.header>
  );
}

export default DesktopNavigation;
