import React, { useState } from 'react';
import { Button, Container } from '@charlietango/ui';
import { rem } from 'polished';

import Anchor from '../../components/Anchor/Anchor';
import { useDictionary } from '../../hooks/useDictionary';
import MenuIcon from '../../icons/MenuIcon';
import { ButtonVariant } from '../../styles/buttons';
import { Colors } from '../../styles/colors';
import { motion } from '../../utils/motion';
import LinkViewModel from '../../view-models/LinkViewModel';
import MobileMenuModal from './MobileMenuModal';
import Logo from '../../components/Logo/Logo';

export type MobileNavigationProps = {
  logo?: LinkViewModel;
  navigationLinks?: LinkViewModel[];
  serviceMenuLinks?: LinkViewModel[];
};

const menuVariants = {
  show: { opacity: 1 },
  hide: { opacity: 0 },
};

function MobileNavigation({ logo, ...rest }: MobileNavigationProps) {
  const { t } = useDictionary();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Container
        sx={{
          display: ['block', 'none'],
          position: 'sticky',
          boxShadow: `0 1px 0 0 #eeeeee`,
          top: 0,
          height: rem(56),
          bg: Colors.PureWhite,
          px: 0,
          zIndex: 1,
        }}
      >
        <motion.div
          animate={!isOpen ? 'show' : 'hide'}
          variants={menuVariants}
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Anchor
            prefetch
            href={logo?.href ?? '/'}
            aria-label={logo?.label}
            sx={{ ml: 5 }}
          >
            <Logo sx={{ width: 32 }} />
          </Anchor>
          <Button
            aria-label={t('ui.openMenu')}
            variant={ButtonVariant.Flat}
            onClick={() => setIsOpen(true)}
            sx={{
              height: rem(56),
              px: 2,
              mr: 2,
            }}
          >
            <MenuIcon css={{ height: rem(24), width: rem(24) }} />
          </Button>
        </motion.div>
      </Container>
      <MobileMenuModal
        {...rest}
        onDismiss={() => setIsOpen(false)}
        isOpen={isOpen}
      />
    </>
  );
}

export default MobileNavigation;
