import React from 'react';
import { Container } from '@charlietango/ui';
import RichText from '../RichText/RichText';
import { TextVariant } from '../../styles/typography';

export type AlertBannerProps = {
  message?: string;
};

function AlertBanner({ message }: AlertBannerProps) {
  if (!message) return null;
  return (
    <div
      role="alert"
      sx={{
        color: 'rgb(102, 60, 0)',
        borderBottom: '1px solid rgba(102, 60, 0, 0.1)',
        backgroundColor: 'rgb(255, 244, 229)',
        p: '1rem 0',
      }}
      // Add nosnippet to prevent Google from indexing
      // https://developers.google.com/search/docs/advanced/crawling/special-tags#inline-directives
      data-nosnippet=""
    >
      <Container>
        <RichText variant={TextVariant.Alert} sx={{ maxWidth: '80ch' }}>
          {message}
        </RichText>
      </Container>
    </div>
  );
}

export default AlertBanner;
