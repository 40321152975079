/*
 GENERATED FILE - DO NOT MODIFY
 Any changes will be overwritten when `yarn svgs` is executed.
 
 Generated from: assets/menu.svg
 If you need to make changes the attributes, modify the original .svg file.
 
 If you need to modify the file further, consider copying this generated file to a new location,
 or into the component that uses it. If you do this, it might make sense to remove the original .svg file, 
 or it could be regenerated the next time SVGs are generated.
*/
import * as React from 'react';
const MenuIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, svgRef: any) => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      focusable={false}
      ref={svgRef}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M22 18v2H2v-2h20zm0-7v2H2v-2h20zm0-7v2H2V4h20z"
        />
      </g>
    </svg>
  ),
);
export default MenuIcon;
