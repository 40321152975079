import React, { useState } from 'react';
import { Button, Dialog, DialogContent } from '@charlietango/ui';
import { rem } from 'polished';

import Logo from '../../components/Logo/Logo';
import { useDictionary } from '../../hooks/useDictionary';
import CloseIcon from '../../icons/CloseIcon';
import { ButtonVariant } from '../../styles/buttons';
import { Colors } from '../../styles/colors';
import { motion } from '../../utils/motion';
import LinkViewModel from '../../view-models/LinkViewModel';
import Navigation from './Navigation';
import ServiceMenu from './ServiceMenu';

export type Props = {
  isOpen?: boolean;
  onDismiss: () => void;
  navigationLinks?: LinkViewModel[];
  serviceMenuLinks?: LinkViewModel[];
};

const navigationVariants = {
  show: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
  hide: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
};

export default function MobileMenuModal({
  isOpen = false,
  onDismiss,
  navigationLinks,
  serviceMenuLinks,
}: Props) {
  const { t } = useDictionary();
  // Normally we would use `<AnimatePresence>` from ../../utils/motion for handling if we should render.
  // But, for some reason this creates an obscure error on some iPhones, that breaks the animation early
  // leaving us in a faded state.
  const [shouldRender, setShouldRender] = useState(isOpen);
  // If the modal isn't open, and it shouldn't render, just return `null`
  if (!isOpen && !shouldRender) return null;

  return (
    <Dialog isOpen onDismiss={onDismiss} css={{ zIndex: 2 }}>
      <DialogContent aria-label={t('ui.menuDialog')} css={{ height: '100%' }}>
        <motion.div
          animate={isOpen ? 'show' : 'hide'}
          onAnimationComplete={() => {
            setShouldRender(isOpen);
          }}
          onAnimationStart={() => {
            setShouldRender(true);
          }}
          initial="hide"
          variants={navigationVariants}
          sx={{
            height: '100%',
            bg: Colors.PureWhite,
          }}
        >
          <div
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              py: 2,

              height: rem(54),
            }}
          >
            <Button
              variant={ButtonVariant.Flat}
              onClick={onDismiss}
              aria-label={t('ui.close')}
              sx={{
                padding: 0,
                minHeight: 0,
                width: 'auto',
                mr: 2,
              }}
            >
              <CloseIcon css={{ height: rem(40), width: rem(40) }} />
            </Button>
          </div>
          <div>
            <Logo
              animateIn
              sx={{
                height: 70,
                width: 70,
                mb: 24,
                ml: 20,
              }}
            />
          </div>
          <div
            sx={{
              borderTop: '1px solid',
              borderTopColor: Colors.VeryLightPink,
            }}
          >
            {navigationLinks && (
              <Navigation onClick={onDismiss} links={navigationLinks} />
            )}
            <ServiceMenu onClick={onDismiss} links={serviceMenuLinks} />
          </div>
        </motion.div>
      </DialogContent>
    </Dialog>
  );
}
