/*
 GENERATED FILE - DO NOT MODIFY
 Any changes will be overwritten when `yarn svgs` is executed.
 
 Generated from: assets/close.svg
 If you need to make changes the attributes, modify the original .svg file.
 
 If you need to modify the file further, consider copying this generated file to a new location,
 or into the component that uses it. If you do this, it might make sense to remove the original .svg file, 
 or it could be regenerated the next time SVGs are generated.
*/
import * as React from 'react';
const CloseIcon = React.forwardRef(
  (props: React.SVGProps<SVGSVGElement>, svgRef: any) => (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      focusable={false}
      ref={svgRef}
      {...props}
    >
      <g fill="#757676" fillRule="evenodd">
        <path d="M10.808 27.778l16.97-16.97 1.414 1.414-16.97 16.97z" />
        <path d="M10.808 12.222l1.414-1.414 16.97 16.97-1.414 1.414z" />
      </g>
    </svg>
  ),
);
export default CloseIcon;
