import React from 'react';

import Anchor from '../../components/Anchor/Anchor';
import { Colors } from '../../styles/colors';
import LinkViewModel from '../../view-models/LinkViewModel';
import { navigationLinkStyle } from './Header';

export type NavigationProps = {
  links: LinkViewModel[];
  onClick?: () => void;
};

function Navigation({ links, onClick }: NavigationProps) {
  return (
    <>
      {links.map((link, key) => (
        <Anchor
          prefetch
          key={key}
          onClick={onClick}
          sx={{
            display: ['block', 'inline-block'],
            background: [Colors.White, 'transparent'],
            borderBottom: ['1px solid', 'none'],
            ...navigationLinkStyle,
          }}
          {...link}
        />
      ))}
    </>
  );
}

export default Navigation;
