import { useEffect, useState } from 'react';
import useClientHydrated from '@charlietango/use-client-hydrated';
import { useTheme } from '@emotion/react';

const defaultBreakpoints = [];

/**
 * useBreakpoints returns the state of all our breakpoints defined in the theme.
 * You'll get back an array containing a boolean, indicating of the current breakpoint matches.
 * Initially the value will be `undefined` until the application has been fully hydrated.
 *
 * The state is not shared between hooks.
 * */
function useBreakpoints() {
  const hydrated = useClientHydrated();
  const { breakpoints: themeBreaks } = useTheme();
  const breakpoints = Array.isArray(themeBreaks)
    ? themeBreaks
    : defaultBreakpoints;

  const [queryMatch, setQueryMatch] = useState<Array<boolean | undefined>>(
    () => {
      if (hydrated) {
        // Once the client has been hydrated, we can read the value into the initial state
        breakpoints.map((media: string) => {
          return window.matchMedia(`(min-width: ${media})`).matches;
        });
      }
      return Array(breakpoints.length).fill(undefined);
    },
  );

  useEffect(() => {
    const handleQueryListener = () => {
      setQueryMatch(matches.map((match) => match.matches));
    };

    const matches: MediaQueryList[] = breakpoints.map((media: string) => {
      const query = `(min-width: ${media})`;
      const match = window.matchMedia(query);
      if ('addEventListener' in match) {
        match.addEventListener('change', handleQueryListener);
      } else {
        // @ts-ignore
        match.addListener(handleQueryListener);
      }

      return match;
    });

    setQueryMatch(matches.map((match) => match.matches));

    return () => {
      matches.forEach((match) => {
        if ('removeEventListener' in match) {
          match.removeEventListener('change', handleQueryListener);
        } else {
          // @ts-ignore
          match.removeListener(handleQueryListener);
        }
      });
    };
  }, [breakpoints]);

  return queryMatch;
}

export { useBreakpoints };
