import React from 'react';

import { useAuth } from '../../api/AuthProvider';
import { useUser } from '../../api/hooks/useUser';
import Anchor from '../../components/Anchor/Anchor';
import { useDictionary } from '../../hooks/useDictionary';
import { Colors } from '../../styles/colors';
import LinkViewModel from '../../view-models/LinkViewModel';

export type ServiceMenuProps = {
  links?: LinkViewModel[];
  onClick?: () => void;
};

const serviceLinkStyle = {
  background: [Colors.White, 'transparent'],
  display: ['block', 'inline-block'],
  fontSize: [2, 1],
  lineHeight: [1.53, 1.71],
  marginLeft: [0, '20px', '24px'],
  textDecoration: 'none',
  color: [Colors.Black, Colors.Secondary],
  padding: ['16px 20px', 0],
  borderBottom: ['1px solid', 'none'],
  borderBottomColor: Colors.VeryLightPink,
  transition: 'color .3s ease',
  '&:hover': {
    color: [null, Colors.Black],
  },
};

const onFocus = () => {
  // If the anchor gets focus, make sure to reset the scroll to the top of the viewport
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);
  }
};

function ServiceMenu({ links, onClick }: ServiceMenuProps) {
  const { loginUrl, logoutUrl } = useAuth();
  const { user, userReady } = useUser();

  const { t } = useDictionary();

  return (
    <div style={{ visibility: !userReady ? 'hidden' : undefined }}>
      {links?.map((link, key) => (
        <Anchor
          prefetch
          key={key}
          onFocus={onFocus}
          onClick={onClick}
          sx={serviceLinkStyle}
          {...link}
        />
      ))}
      {userReady ? (
        <Anchor
          onFocus={onFocus}
          external
          prefetch={false}
          sx={serviceLinkStyle}
          href={user ? logoutUrl : loginUrl}
          label={t(user ? 'ui.logout' : 'ui.login')}
        />
      ) : null}
    </div>
  );
}

ServiceMenu.displayName = 'ServiceMenu';

export default ServiceMenu;
